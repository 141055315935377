<script>
export default {
    name:'RechrgeSuccessfully',
    components: {
        Header: () => import('components/base/Header'),
        'mt-header': app.Mint.Header,
        'mt-button': app.Mint.Button,
    },
    data() {
        var rechargeData=this.$route.query
        return {
            rechargeData:rechargeData,
            bank: '',
            bank_num: '',
            bank_name: '',
            cardTypeName: '',
            money: '',

            header:{
                mainTitle:rechargeData?getWord(['bankcard', 'information']):getWord(['recharge','success']),
            },
        }
    },
    methods: {
        goBack(){
            this.$router.go(-1);
        }
    },
    mounted() {
        this.money = this.$route.params.num;
    },
}
</script>
<template>
    <div id="offline-order-success" :class="_TEMPLATE">
        <template v-if="rechargeData.type">
            <Header 
                :mainTitle="header.mainTitle" 
                :backButton=false 
            />

            <div class="form">
                <fieldset>
                    <label>{{ getWord('username5') }}</label>
                    <span>{{ rechargeData.accName }}</span>
                </fieldset>
                <fieldset>
                    <label>{{ getWord('bannkcard3') }}</label>
                    <span>{{ rechargeData.accNo }}</span>
                </fieldset>
                <fieldset>
                    <label>{{ getWord(['withdrawal', 'amount_of_betting']) }}</label>
                    <span>{{ rechargeData.amount }}</span>
                </fieldset>
                <fieldset>
                    <label>{{ getWord('bank_name') }}</label>
                    <span>{{ rechargeData.bankName }}</span>
                </fieldset>
                <fieldset>
                    <label>{{ getWord('bank_branch_name') }}</label>
                    <span>{{ rechargeData.branch }}</span>
                </fieldset>

                <h6>{{ getWord('transfer_rules4') }}</h6>
                <button class="submit" @click="goBack()">{{ getWord('ok') }}</button>
            </div>
        </template>
        <div class="main" v-else>
            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                <mt-header :title="getWord(['payment', 'page2'])" style="height:0.88rem;font-size: 0.35rem;">
                    <mt-button icon="back"></mt-button>
                </mt-header>
                <div class="main_image"></div>
            </template>
            <template v-if="_TEMPLATE==='template-3'">
                <Header 
                    :mainTitle="header.mainTitle" 
                    :backButton=false 
                />
                <i class="icon icon-success"></i>
            </template>

            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                <span class="span_1">{{ getWord(['submitted', 'apply_for']) }}</span>
                <span class="span_2">{{ getWord('contact_service9') }}</span>
            </template>
            <template v-if="_TEMPLATE==='template-3'">
                <span class="span_1">{{ getWord('contact_service10') }}</span>
            </template>

            <span class="span_3">￥{{money}}</span>

            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                <router-link to="/money">
                    <mt-button class="main_button" type="primary">{{ getWord('done') }}</mt-button>
                </router-link>
            </template>
            <template v-if="_TEMPLATE==='template-3'">
                <router-link to="/p">{{ getWord('done') }}</router-link>
            </template>
        </div>
    </div>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
#offline-order-success {

    &.template-3 {

        .main {

            .span_1 {
                display: block;
                float: none;
                font-size: .3rem;
                color: #666666;
            }

            .span_3 {
                height: auto;
                font-weight: normal;
                display: block;
                float: none;
                color: #000000;
                font-size: .7rem;
                line-height: 1rem;
            }

            .icon {
                width: 2rem;
                height: 2rem;
                display: block;
                margin: 1rem auto .5rem;
                background-color: #eeeeee;
                border-radius: 100%;

                &.icon-success {
                    background-color: #F35959;
                    background-size: 70%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-image: url('/static/assets/images/personal/icons/success-right.png');
                }
            }

            > a {
                font-size: .34rem;
                border-radius:7px;
                display: block;
                background-color: #26a2ff;
                width: 84%;
                padding:.2rem 0;
                color: #ffffff;
                margin:.5rem auto;
                text-align: center;
            }

            > a {
                background-color: #EC2829;
            }
        }

        .form {

            button {

                &.submit {
                    background-color: #EC2829;
                }
            }
        }
    }

    &.template-2 {

        .main {

            header {
                background-color: $TEMPLATE2-theme-color;             
            }

            a {

                /deep/ .mint-button--primary {
                    background-color: $TEMPLATE2-theme-color;
                }
            } 
        }
    }

    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .main_image {
        float: left;
        height: 1.07rem;
        width: 1.07rem;
        background-image: url('/static/assets/images2/money/gou.png');
        background-size: 100%;
        margin: 2.4rem 0 0 3.3rem;
    }

    .main_button {
        float: left;
        width: 3.98rem;
        height: 0.68rem;
        margin: 0.6rem 0 0 1.8rem;
    }

    .span_1 {
        float: left;
        width: 100%;
        height: 0.28rem;
        font-size: 0.3rem;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        margin-top: 0.2rem;
    }

    .span_2 {
        float: left;
        width: 100%;
        height: 0.28rem;
        font-size: 0.3rem;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        margin-top: 0.1rem;
    }

    .span_3 {
        float: left;
        width: 100%;
        height: 0.28rem;
        font-size: 0.36rem;
        font-family: MicrosoftYaHei-Bold;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        text-align: center;
        margin-top: 0.1rem;
    }

    .form {
        padding: 0 .25rem;

        .fieldset {
            display: flex;
            align-items: center;
            padding: 0;
            border:0;

            &:first-child {
                border-top:0;
            }

            label {
                width: 1.8rem;
                font-size: .28rem;
                line-height: 1;
            }

            input {
                width: 4.7rem;
                font-size: .28rem;
                line-height: 2;
                padding: 0.05rem .25rem;
                box-sizing: border-box;

                &:disabled {
                    background-color: transparent;
                    width: 3.3rem;
                }
            }

            > h6 {
                width: .4rem;
                padding: 0 .25rem;

                .iconfont {
                    color: #EC2829;
                }

                img {
                    height: .3rem;
                    vertical-align: middle;
                }
            }

            > div {
                border-bottom: 1px solid #d0d0d0;
                width: 100%;
                padding: 0.1rem 0;
                position: relative;

                &.standalone {
                    border-top: 1px solid #d0d0d0;

                    label {
                        color: #5F646E;
                    }
                }

                &.select {                                

                    .icon-back {
                        position: absolute;
                        top:50%;
                        transform: rotateZ(270deg) translateY(-.5rem) translateX(50%);
                        transform-origin: center;
                        opacity: .4;
                    }
                }
            }

            .copy {
                background-color: #EDB93F;
                display: inline-block;
                font-size: .28rem;
                color: #ffffff;
                text-align: center;
                white-space: nowrap;
                padding: .14rem .45rem;
                border-radius: 3px;
                position: absolute;
                right: .1rem;
            }
        }
    }

    .form {

        button {

            &.submit {
                font-size: .3rem;
                display: block;
                background-color: $TEMPLATE2-theme-color;
                color: #fff;
                border: 0;
                width: 98%;
                margin: 0 auto;
                padding: .2rem 0;
            }
        }

        > input {
            width: 98%;
            border: 1px solid #cccccc;
            padding: .25rem;
            box-sizing: border-box;
            margin: .25rem 1%;

            &::placeholder {
               color: #cccccc;
            }
        }

        fieldset,.fieldset {
            border:0;
            border-bottom:1px solid #f5f5f9;
            font-size: .3rem;
            padding: .25rem 0;

            &:first-child {
                border-top:1px solid #f5f5f9;
            }

            label {
                width: 1.6rem;
                display: inline-block;
            }

            input {
                font-size: .3rem;
                border:0;
                width: 5rem;
            }
        }

        > h6 {
            margin: .5rem 0 .25rem;
            font-size: .24rem;
            font-weight: normal;
            text-align: center;
        }
    }
}
</style>